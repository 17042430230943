import React from "react";
import "react-piano/dist/styles.css";
import SaxNote from "./Header";
import InteractiveDemo from "./InteractiveDemo";
import "./App.css";

const audioContext = new window.AudioContext();
const soundfontHostname = "https://d1pzp51pvbm36p.cloudfront.net";
class App extends React.Component {
  state = { key: "-" };

  render() {
    return (
      <div>
        <SaxNote SaxNote={this.state} />
        {/* {this.state.key} */}
        <div className="container">
          <div className="row mt-1">
            <div className="col-md-8 offset-md-2">
              <InteractiveDemo
                setKey={(key) => this.setState({ key: key })}
                audioContext={audioContext}
                soundfontHostname={soundfontHostname}
              />
            </div>
          </div>
          {/* <hr className="mt-5" /> */}
          <hr />
          {/* <hr className="mt-5" /> */}
          <p className="small fixed-bottom text-center text-warning">
            Deeveloped by Pritpal Marjara
          </p>
        </div>
      </div>
    );
  }
}

export default App;
