export const SaxKeys = [
  { key: 48, note: "s1234567" },
  { key: 49, note: "s1234567b" },
  { key: 50, note: "s123456" },
  { key: 51, note: "s123456d" },
  { key: 52, note: "s12345" },
  { key: 53, note: "s1234" },
  { key: 54, note: "s1235" },
  { key: 55, note: "s123" },
  { key: 56, note: "s123g" },
  { key: 57, note: "s12" },
  { key: 58, note: "s12a" },
  { key: 59, note: "s1" },
  { key: 60, note: "s2" },
  { key: 61, note: "s" },
  { key: 62, note: "s1234568" },
  { key: 63, note: "s123456d8" },
  { key: 64, note: "s123458" },
  { key: 65, note: "s12348" },
  { key: 66, note: "s12358" },
  { key: 67, note: "s1238" },
  { key: 68, note: "s123g8" },
  { key: 69, note: "s128" },
  { key: 70, note: "s12a8" },
  { key: 71, note: "s18" },
  { key: 72, note: "s28" },
];
