import React from "react";
import { SaxKeys } from "./saxkeys";

const Header = (props) => {
  function GetKey(key) {
    return SaxKeys.filter((el) => {
      return el.key === key;
    });
  }
  return (
    <div>
      <div className="container  p-1">
        {/* {props.SaxNote.key} */}
        <div className="text-center border-2 border shadow-lg bg-light ">
          {/* {props.SaxNote.key} */}
          <span className="text-dark SaxNote">
            {GetKey(props.SaxNote.key)[0]?.note}
          </span>
           
        </div>
        {/* <button
          onClick={() => {
            console.log(GetKey(props.SaxNote.key)[0].note);
          }}
        >
          test
        </button> */}
      </div>
    </div>
  );
};

export default Header;
